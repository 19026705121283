<template>
  <b-container fluid>
    <b-row>
      <b-col cols="12" class="d-flex justify-content-center mt-2">
        <b-form-group 
          class="mx-1 orgs-select"
          label-for="organization-multi-select" 
          label="Organization"
          label-class="text-uppercase"
        > 
        <v-select
          id="organization-multi-select"
          multiple
          :options="options"
          label="name"
          v-model="selectedOrganization"
          :closeOnSelect="false"
          :clearable="true"
          placeholder="Select an organization"
          />
          <template slot="description">
            <div class="text-left" :class="errOrgMax ? 'text-danger text-uppercase animate__animated animate__headShake' : 'text-seecondary'">
              Max. 10
            </div>
          </template>
        </b-form-group>
        <b-form-group 
          class="d-inline-block text-left ml-2"
          label-for="to-date-picker" 
          :label="$t('common.terms.to')"
          label-class="text-uppercase"
        >
          <flat-pickr
            id="to-date-pickr"
            placeholder="No date selected"
            v-model="period"
            class="form-control"
            :config="flatPickrConfig"
          />
        </b-form-group>
        <div class="mt-2 ml-3">
          <b-button
            id="filter-button"
            :class="loadingItems ? 'py-75 px-1' : ''"
            :disabled="loadingItems"
            variant="info"
            @click="refreshItems"
          >
            <feather-icon
              v-if="!loadingItems"
              icon="SearchIcon"
              class="mr-50 p-0"
            />
            <b-spinner v-else class="mr-75 ml-50" small/>
            <span>Filter</span>
          </b-button>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col v-if="items">
        <b-row v-for="(item, key, index) in items" :key="key + index">
          <div class="d-flex w-100 mb-50 ml-1">
            <h1>
              <span class="font-weight-bold" @click="toggleCollapse(index)">
                {{ key }}
              </span>
            </h1>
          </div>
          <div class="w-100">
            <flickity class="position-relative flickity" ref="flickity" :options="{initialIndex: 0, prevNextButtons: false, pageDots: false, wrapAround: false, adaptiveHeight: false, cellAlign: 'left'}">
              <b-card
                v-for="stats in item" :key="stats.name"
                class="card-stats mx-50"
                rounded
                bg-variant="light-blue"
              >
                <b-row class="d-flex">
                  <b-col cols="12">
                    <div>
                      <h3>
                        <span class="font-weight-bold text-uppercase text-truncate-ellipsis d-inline-block">
                          {{ stats.name }}
                        </span>
                      </h3>
                    </div>
                  </b-col>
                </b-row>
                <b-row>    
                  <b-col cols="6">  
                    <span class="text-info font-weight-bold cursor-pointer" @click="openTransmissionDashboard(stats.id)">
                      + Detalhes
                    </span>
                    <h2 class="total-ativacoes position-relative">
                      <span class="text-primary">
                        {{ stats.total || 0 }}
                        <small>
                          Ativações
                        </small>
                      </span>
                    </h2>
                  </b-col>
                  <b-col cols="6">  
                    <div class="donut-chart">
                      <vue-apex-charts :options="chartOptions" :series="stats.series"></vue-apex-charts>
                    </div>
                  </b-col>
                </b-row>
              </b-card>
            </flickity>
          </div>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { BRow, BCol, BButton, BFormGroup, BFormCheckbox, BSpinner, BContainer, BCard, BCollapse } from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import OrganizationSelect from "@/layouts/components/OrganizationSelect.vue";
import vSelect from "vue-select";
import { makeToast } from '@/layouts/components/Popups';
import VueApexCharts from 'vue-apexcharts'
import Flickity from 'vue-flickity';

import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect' // Plugin flatpicker para data
require('flatpickr/dist/plugins/monthSelect/style.css') // CSS do plugin

  export default {
    components: {
      BRow,
      BCol,
      BButton,
      BFormGroup,
      BFormCheckbox,
      BSpinner,
      BContainer,
      flatPickr,
      OrganizationSelect,
      vSelect,
      makeToast,
      BCard,
      VueApexCharts,
      Flickity,
      BCollapse
    },
    data() {
      return {
        flatPickrConfig: {
          altInput: true,
          plugins: [
            new monthSelectPlugin({
              dateFormat: "n/Y", //defaults to "F Y"
              altFormat: "F Y", //defaults to "F Y"
              theme: "dark" // defaults to "light"
            })
          ] 
        },

        items: null,
        loadingItems: false,
        collapse: [],
        selectedOrganization: [],
        options: [],
        filters: {},
        from: null,
        period: null,

        rawChartData: {},
        chartData: {},   

        chartOptions: {
          chart: {
            type: 'donut',
          },
          legend: {
            show: false,
          },
          stroke: {
            show: false,
          },
          colors: [
            '#12e066',
            "#161d31"
          ],
          labels: [
            'Usadas',
            'Restantes'
          ],
          dataLabels: {
            enabled: false,
          }
        }
      }
    },
    created() {
      this.fetchOrganization()
    },
    mounted() {
      this.setDefaultInterval()
    },
    computed: {
      errOrgMax() {
        return this.selectedOrganization.length > 10 ? true : false
      }
    },
    methods: {
      refreshItems() {
        this.buildTransmissionsByOrganization()
      },
      async getOrganizationDashboard(organization, period) {
        this.$store.dispatch('getOrganizationStats', { organization, params: { period }}).then((response) => {
          this.buildDashData(response)
        })
      },
      buildDashData(rawData) {
        let data = rawData
        let data_map = {}
        
        data.forEach((el) => {
          data_map[el.transmission] = el.tokens
        })

        
        let orgs = this.organizations
        console.log(data_map, orgs)
        let items = {}
        Object.keys(orgs).forEach(key => {
          this.items[key] = []
          orgs[key].forEach((el) => {
            console.log(data_map[el.identifier], el.identifier)
            this.items[key].push({ "name": el.name, id: el.id, total: data_map[el.identifier] || 0, series: [ data_map[el.identifier] || 0, 5000 - (data_map[el.identifier] || 0)] })
          })
        })
        console.log(this.items)
        this.loadingItems = false
      },
      async buildTransmissionsByOrganization() {
        let orgs = this.selectedOrganization
        let organizations = {}
        let transmissions = []

        if (orgs.length > 10) {
          return
        }
        this.loadingItems = true

        new Promise((resolve) => {
          orgs.forEach(async (org) => {
            let resp = await this.fetchTransmissionGroupByOrganization(org) // fazer rota na api para pegar varias trans por um array de orgs
            
            organizations[org.name] = resp.map((el) => { return { identifier: el.identifier, name: el.name, id: el.id }})
            transmissions.push(...resp.map((el) => el.identifier))
            if (orgs.length == Object.keys(organizations).length) resolve();
          })
        }).then(() => {
          this.organizations = organizations;
          this.items = {}
          new Promise((resolve) => {
            orgs.forEach((organization) => {
              this.$store.dispatch('getOrganizationStats', { organization: organization.id, params: { period: this.period }}).then((response) => {
                this.buildDashData(response)
              })
            })

            resolve();
          })
        })
      },
      setDefaultInterval() {
        this.period = Date.now();
      },
      fetchOrganization() {
        this.$store
          .dispatch("getOrganizationByLoggedUser")
          .then((response) => {          
            let idx = response.findIndex(el=> String(el.id) == localStorage.getItem('selectedOrganization') )

            if (localStorage.getItem('selectedOrganization') && idx > -1) {

              this.selectedOrganization.push(response[idx])

            } else {
              this.selectedOrganization.push(response[0])
            }
            
            this.buildTransmissionsByOrganization()

            this.options = response;
          })
          .catch((e) => {
            console.log(e)
            makeToast({
              title: '',
              text:'',
              variant: "danger",
              icon: "XIcon",
            }); 
          });
      },
      async fetchTransmissionGroupByOrganization(organization) {
        return this.$store
          .dispatch("getTransmissionOrganizationForDashboard", { organization: organization.id })
          .then((response) => {
            console.log(response)
            return response
          })
          .catch((error) => {
            console.log(error)
            makeToast({
              title: this.$t(
                "middleware.toast.transmission.fetch.error.title"
              ),
              text: this.$t(
                "middleware.toast.transmission.fetch.error.message"
              ),
              variant: "danger",
              icon: "XIcon",
            });
          });
      },
      toggleCollapse(index) {
        this.$set(this.collapse, index, !this.collapse[index])
      },
      openTransmissionDashboard(transmission_id) {
        const routeData = this.$router.resolve({name: 'transmission-dashboard', params: { transmissionID: transmission_id } });
        window.open(routeData.href, '_blank');
      }
    },
  }
</script>

<style lang="scss" scoped>

.card-stats {
  max-height: 200px !important;
  height: 200px !important;
  width: 400px !important;
  min-width: 400px !important;
  max-width: 400px !important;
  .text-truncate-ellipsis {
    width: 360px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.flickity-enabled.is-draggable .flickity-viewport{
  cursor: unset;
}

.flickity-viewport {
  transition: height 0.3s;
}

.total-ativacoes {
  top: 60%;
  left: 0px;
}

.orgs-select {
  width: 30%;
}

</style>